import {LanguageService} from "ifocus-lib";
import {ConstantsHandler} from "../_common/_constant/constants.handler";
import {TranslateService} from "@ngx-translate/core";


export function getTransMessage(translate: TranslateService, key: string): string {
    return LanguageService.getTransMessage(translate, key);
}

export function getDefaultLanguage(translate: TranslateService): string {
    return LanguageService.getDefaultLanguage(translate, ConstantsHandler.multiLanguage);
}
